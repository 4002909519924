<template>
    <div class="language-selector">
        <select
            name="language-selector"
            title="Select language"
            v-model="selectedLanguage"
            @change="onChangeLanguage"
        >
            <option
                v-for="language in languages"
                :key="language.code"
                :value="language.code"
            >
                {{ language.name }}
            </option>
        </select>
    </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
    name: 'LanguageSelector',
    props: {
        languages: {
            type: Array,
            required: true,
        },
        currentLanguage: {
            type: String,
            required: true,
        },
    },
    emits: ['language-changed'],
    setup(props, { emit }) {
        const selectedLanguage = ref(props.currentLanguage);

        const onChangeLanguage = () => {
            emit('language-changed', selectedLanguage.value);
        };

        watch(
            () => props.currentLanguage,
            newVal => {
                selectedLanguage.value = newVal;
            }
        );

        return {
            selectedLanguage,
            onChangeLanguage,
        };
    },
};
</script>

<style scoped>
.language-selector select {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;
}

.language-selector select:focus {
    border-color: #007bff;
}

.language-selector select option {
    background-color: #fff;
    color: #333;
}
</style>
