import { createStore } from 'vuex';
import sidebar from './sidebar';
import auth from './auth';
import i18n from './i18n';
import system from './system';

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        sidebar,
        auth,
        i18n,
        system,
    },
});
