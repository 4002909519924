import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n, { setI18nLocale } from './api/i18n';
import apiClient from './api/index';
import mainPlugins from '@/plugins/mainPlugins.js';
import { setLoading } from './plugins/loading';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const app = createApp(App);

const loadInitialLanguage = async locale => {
    const response = await apiClient.get(
        `/languages/lang/${locale}?disable_cache=false`, { skipAuthorization: true }
    );
    i18n.global.setLocaleMessage(locale, response.data);
    i18n.global.locale.value = locale;
    setI18nLocale(locale, response.data);
    store.commit('i18n/setLocale', { locale, messages: response.data });
};

const initializeApp = async () => {
    const defaultLocale = localStorage.getItem('locale') || 'en';
    await loadInitialLanguage(defaultLocale);

    router.beforeEach(async (to, from, next) => {
        setLoading(true);

        const locale = store.getters['i18n/locale'];
        if (!store.getters['i18n/messages'][locale]) {
            await loadInitialLanguage(locale);
        }

        next();
    });

    router.afterEach(() => {
        setLoading(false);
    });

    app.use(mainPlugins);
    app.use(store);
    app.use(router);
    app.use(i18n);

    app.use(VueSweetalert2);

    app.mount('#app');
};

initializeApp();
