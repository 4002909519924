<template>
    <card class="chart-container">
        <template v-slot:header>
            <h4 v-if="$slots.title || title" class="card-title">
                <slot name="title">{{ title }}</slot>
            </h4>
            <p class="card-category" v-if="$slots.subTitle || subTitle">
                <slot name="subTitle">{{ subTitle }}</slot>
            </p>
        </template>
        <div class="chart-wrapper">
            <Bar
                v-if="chartType === 'bar'"
                :data="processedChartData"
                :options="chartOptions"
                :height="chartHeightValue"
                width="auto"
            />
            <Line
                v-if="chartType === 'line'"
                :data="processedChartData"
                :options="chartOptions"
                :height="chartHeightValue"
            />
            <Pie
                v-if="chartType === 'pie'"
                :data="processedChartData"
                :options="chartOptions"
                :height="chartHeightValue"
                width="auto"
            />
            <div class="footer">
                <div class="chart-legend">
                    <slot name="legend"></slot>
                </div>
                <hr />
                <div class="stats">
                    <slot name="footer"></slot>
                </div>
                <div class="pull-right"></div>
            </div>
        </div>
    </card>
</template>

<script>
import { defineComponent, computed } from 'vue';
import Card from './Card.vue';
import { Bar, Line, Pie } from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineController,
    BarController,
    PieController,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineController,
    BarController,
    PieController,
    ChartDataLabels
);

export default defineComponent({
    name: 'ChartCard',
    components: {
        Card,
        Bar,
        Line,
        Pie,
    },
    props: {
        footerText: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        subTitle: {
            type: String,
            default: '',
        },
        chartType: {
            type: String,
            default: 'line', // line | pie | bar
        },
        chartOptions: {
            type: Object,
            default: () => ({}),
        },
        chartData: {
            type: Object,
            default: () => ({
                labels: [],
                datasets: [],
            }),
        },
        chartHeight: {
            type: String,
            default: '400px', // Default height
        },
        chartColors: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const chartHeightValue = computed(() =>
            parseInt(props.chartHeight.replace('px', ''))
        );

        const processedChartData = computed(() => {
            const datasets = props.chartData.datasets.map(dataset => {
                if (dataset.backgroundColor && dataset.borderColor) {
                    return dataset;
                }
                const backgroundColor = props.chartColors.slice(
                    0,
                    dataset.data.length
                );
                const borderColor = backgroundColor.map(color =>
                    color.replace('0.8', '1')
                );

                return {
                    ...dataset,
                    backgroundColor,
                    borderColor,
                    borderWidth: 1,
                };
            });

            return {
                ...props.chartData,
                datasets,
            };
        });

        return {
            chartHeightValue,
            processedChartData,
        };
    },
});
</script>

<style scoped>
.chart-container {
    display: flex;
}
.chart-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column; /* Ensure the items are aligned in column direction */
    align-items: center;
    justify-content: center;
    height: 600px; /* Avoid height 100% which can cause infinite growth */
    width: 100% !important; /* Ensure width is 100% to fit parent container */
    position: relative;
}
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    width: 100%;
}
.chart-legend,
.stats {
    width: 100%;
}
</style>
