<template>
    <div>
        <TableComponent
            :headers="headers"
            :data="data"
            :current-page="currentPage"
            :currentPageSize="params.page_size"
            :total-pages="totalPages"
            :loading="loading"
            :sortColumn="sortColumn"
            :sortOrder="sortOrder"
            :filterOptions="filterFields"
            :totalCounts="totalCounts"
            :messageRefresh="$t('check_cable.check_cable_msg__refresh')"
            :messagePrevious="$t('check_cable.check_cable_msg__previous')"
            :messageNext="$t('check_cable.check_cable_msg__next')"
            :messageExport="$t('check_cable.check_cable_msg__export')"
            :messagePageSize="$t('check_cable.check_cable_msg__page_size')"
            :messageSearch="$t('check_cable.check_cable_msg__search')"
            :messageSummary="'check_cable.check_cable_msg__table_summary'"
            :pageSizes="[10, 20, 50, 100]"
            :searchFields="searchFields"
            :isSearchTimeRestricted="true"
            :messageSearchTimeDays="'check_cable.check_cable_msg__search_time_range_days'"
            :messageSearchTimeCustom="'check_cable.check_cable_msg__search_time_range_custom'"
            :messageSearchTimeLimit="{
                key: 'check_cable.check_cable_msg__search_time_range_limit_days',
                value: timeRange,
            }"
            @on-refresh="refreshData"
            @on-export="exportData"
            @on-change-page="changePage"
            @on-change-page-size="changePageSize"
            @on-filter="filterData"
            @on-text-filter="textFilterData"
            @on-sort="sortData"
        />
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import TableComponent from '@/components/TableComponent.vue';
import {
    fetchCheckCableResultFromAPI,
    exportCheckCableFromAPI,
    fetchConfig,
} from '@/api/diagnosis/check-cable';

const getDaysAgoFormattedDateRange = daysAgo => {
    const date = new Date();
    const datePast = new Date(date); // Create a new date object to avoid modifying the original date object
    datePast.setDate(datePast.getDate() - daysAgo);
    return {
        gte: datePast.toISOString(),
        lte: date.toISOString(),
    };
};
function calculateDaysDifference(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const dayDifference = (end - start) / (1000 * 60 * 60 * 24);
    return dayDifference;
}

function adjustQueryDate(queryDate) {
    const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
    if (dateTimeRegex.test(queryDate)) {
        return queryDate;
    } else if (dateOnlyRegex.test(queryDate)) {
        const date = new Date(queryDate);
        date.setDate(date.getDate() + 1); // 加 1 天

        const maxDate = new Date(); // 获取当前时间
        maxDate.setDate(maxDate.getDate() + 3); // 设置为当前时间加 3 天

        // 如果调整后的日期超过当前时间的 3 天，设置为当前时间加 3 天
        if (date > maxDate) {
            date.setTime(maxDate.getTime());
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    } else {
        return queryDate;
    }
}

// let isReload = false; // 全局标志

export default {
    components: {
        TableComponent,
    },
    setup() {
        const { t } = useI18n();
        const columnOrder = ref([]);
        const headers = ref([]);
        const data = ref([]);
        const pageSize = ref(10);
        const currentPage = ref(1);
        const totalPages = ref(1);
        const totalCounts = ref(0);
        const filter = ref('');
        const filterFields = ref([]);
        const searchFields = ref([]);
        const sortColumn = ref('');
        const sortOrder = ref('desc');
        const loading = ref(false);
        const params = ref({});
        const timeRange = ref(180);

        const buildParams = () => {
            const newParams = {
                page: currentPage.value,
                page_size: pageSize.value,
                search: filter.value,
            };
            return newParams;
        };
        const loadConfig = async () => {
            const response = await fetchConfig();
            const sortableColumns = response.ordering_fields;
            columnOrder.value = response.display_fields;
            headers.value = columnOrder.value.map(key => ({
                text: t(`check_cable.check_cable_fld__${key}`) || t(key),
                value: key,
                sortable: sortableColumns.includes(key),
            }));
            const responseFilterFields = response.filter_fields;
            filterFields.value = Object.keys(responseFilterFields).map(key => ({
                key: key,
                label: t(`check_cable.check_cable_fld__${key}`) || key,
                type: responseFilterFields[key].type,
                value: '',
                start: '',
                end: '',
                options: responseFilterFields[key].items || [],
            }));
            if (response.upload_time_range) {
                timeRange.value = parseInt(response.upload_time_range);
            }
            if (response.search_fields) {
                searchFields.value = response.search_fields.map(key => ({
                    key: key,
                    label: t(`check_cable.check_cable_fld__${key}`) || key,
                }));
            }
        };
        const fetchData = async () => {
            if (
                calculateDaysDifference(
                    params.value.upload_time__gte,
                    params.value.upload_time__lte
                ) > (timeRange.value + 7)  // 如果时间范围超过限制，多180+7天彈性避免reload後無法送出查詢(reload adjustQueryDate add 1D)
            ) {
                console.log('Time range exceeds limit');
                return;
            }

            if (params.value.upload_time__lte) {
                params.value.upload_time__lte = adjustQueryDate(
                    params.value.upload_time__lte
                );
            }

            loading.value = true;
            try {
                const response = await fetchCheckCableResultFromAPI(
                    params.value
                );
                data.value = response.results;

                currentPage.value = response.current_page;
                totalCounts.value = response.total_count;
                totalPages.value = response.total_pages;
            } catch (error) {
                console.error(error);
            } finally {
                loading.value = false; // 结束加载
            }
        };
        const changePage = page => {
            if (page >= 1 && page <= totalPages.value) {
                params.value.page = page;
                fetchData();
            }
        };
        const changePageSize = size => {
            params.value.page_size = size;
            fetchData();
        };
        const filterData = filter => {
            if (filter.type === 'datetime') {
                params.value[`${filter.key}__gte`] = filter.start;
                params.value[`${filter.key}__lte`] = filter.end;
            }
            params.value.page = 1;
            fetchData();
        };
        const sortData = (columnIndex, order) => {
            sortColumn.value = columnIndex;
            sortOrder.value = order;
            params.value.ordering = `${sortOrder.value === 'desc' ? '-' : ''}${
                sortColumn.value
            }`;
            params.value.page = 1;
            fetchData();
        };
        const textFilterData = search => {
            if (search.field !== '') {
                params.value.search_fields = search.field;
            } else {
                params.value.search_fields = searchFields.value
                    .map(item => item.key)
                    .join(',');
            }
            params.value.search = search.text;
            params.value.page = 1;
            sortColumn.value = '';
            sortOrder.value = 'desc';
            delete params.value.ordering;
            fetchData();
        };
        const exportData = async type => {
            const response = await exportCheckCableFromAPI(params.value, type);
            if (type === 'csv') {
                // download csv, the response will be directly csv text
                const blob = new Blob([response], {
                    type: 'text/csv',
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'check-cable-report.csv';
                a.click();
                window.URL.revokeObjectURL(url);
            } else if (type === 'excel') {
                const blob = new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'check-cable-report.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);
            }
        };
        onMounted(async () => {
            await loadConfig();
            const { gte, lte } = getDaysAgoFormattedDateRange(timeRange.value);
            Object.assign(params.value, buildParams());
            params.value.upload_time__gte = gte;
            params.value.upload_time__lte = lte;

            await fetchData();
        });

        const refreshData = async () => {
            // isReload = true; // 标记为 reload 操作
            const currentTime = new Date(); // 获取当前时间
            const fourHoursInSeconds = 4 * 60 * 60; // 4 小时的秒数

            // 检查 upload_time__lte 是否存在
            if (params.value.upload_time__lte) {
                const uploadTimeLte = new Date(params.value.upload_time__lte);

                // 计算与当前时间的秒差
                const timeDifferenceInSeconds = Math.abs((currentTime.getTime() - uploadTimeLte.getTime()) / 1000);

                // 如果秒差在 4 小时内，更新 upload_time__lte 为当前时间
                if (timeDifferenceInSeconds <= fourHoursInSeconds) {
                    params.value.upload_time__lte = currentTime.toISOString(); // 更新 `upload_time__lte` 为当前时间
                }
            } else {
                // 如果 `upload_time__lte` 不存在，直接设置为当前时间
                params.value.upload_time__lte = currentTime.toISOString();
            }
            // console.log('Updated params with buildParams:', params.value);
            // 呼叫 fetchData 來抓取資料
            await fetchData();
            // isReload = false; // 恢复标志
        };

        return {
            headers,
            data,
            currentPage,
            totalPages,
            totalCounts,
            loading,
            filterFields,
            searchFields,
            sortColumn,
            sortOrder,
            params,
            fetchData,
            refreshData,
            changePage,
            changePageSize,
            filterData,
            sortData,
            textFilterData,
            exportData,
            timeRange,
        };
    },
};
</script>
