import apiClient from '.';
import DOMPurify from 'dompurify';

export const fetchPrivacyPolicy = async () => {
    const containsUnsafeKeywords = html => {
        const unsafePatterns = [
            /eval\(/i,
            /<script\b/i,
            /javascript:/i,
            /onerror=/i,
        ];
        return unsafePatterns.some(pattern => pattern.test(html));
    };
    let sanitizedHtml = '';
    let lang = localStorage.getItem('locale') || 'en';
    try {
        const response = await apiClient.get(`/languages/policy/${lang}`, { skipAuthorization: true });
        const unsafeHtml = response.data.content;

        if (containsUnsafeKeywords(unsafeHtml)) {
            console.warn('The privacy policy contains unsafe keywords');
            sanitizedHtml =
                'The privacy policy contains unsafe keywords, please contact the administrator';
            return sanitizedHtml;
        }

        sanitizedHtml = DOMPurify.sanitize(unsafeHtml);
        return sanitizedHtml;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
