<template>
    <div class="table-container">
        <nav class="navbar navbar-expand-lg table-nav">
            <div class="container-fluid align-items-start">
                <div class="navbar-collapse navbar-expand-lg">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="#"
                                @click="$emit('onRefresh')"
                            >
                                <i class="fa fa-refresh"></i>
                                <p>{{ messageRefresh }}</p>
                            </a>
                        </li>
                        <drop-down
                            v-if="showExportButton"
                            class="nav-item"
                            :title="messageExport"
                            title-classes="nav-link"
                            icon="fa fa-download"
                        >
                            <a
                                class="nav-link"
                                href="#"
                                @click="$emit('onExport', 'csv')"
                            >
                                <i class="fa fa-file-text-o"></i>
                                <p>CSV</p>
                            </a>
                            <a
                                class="nav-link"
                                href="#"
                                @click="$emit('onExport', 'excel')"
                            >
                                <i class="fa fa-file-excel-o"></i>
                                <p>Excel</p>
                            </a>
                        </drop-down>
                        <drop-down
                            class="nav-item"
                            :title="messagePageSize"
                            title-classes="nav-link"
                            icon="fa fa-arrows-v"
                        >
                            <a
                                v-for="pageSize in pageSizes"
                                :key="pageSize"
                                class="dropdown-item"
                                href="#"
                                @click="$emit('onChangePageSize', pageSize)"
                            >
                                {{ pageSize }}
                            </a>
                        </drop-down>
                        <slot name="table-actions"></slot>
                    </ul>
                </div>
                <FilterComponent
                    :filterOptions="filterOptions"
                    :messageSearch="messageSearch"
                    :searchFields="searchFields"
                    :isSearchTimeRestricted="isSearchTimeRestricted"
                    :messageSearchTimeDays="messageSearchTimeDays"
                    :messageSearchTimeCustom="messageSearchTimeCustom"
                    :messageSearchTimeLimit="messageSearchTimeLimit"
                    @on-text-filter="$emit('onTextFilter', $event)"
                    @on-filter="$emit('onFilter', $event)"
                />
            </div>
        </nav>
        <div class="table-content">
            <span v-if="loading" class="loading-container"></span>
            <table
                v-else
                class="table table-striped table-responsive table-full-width"
            >
                <thead>
                    <tr>
                        <th v-if="selectable">
                            <input
                                v-if="selectionMode === 'multiple'"
                                type="checkbox"
                                @change="toggleSelectAll"
                                :checked="allSelected"
                            />
                        </th>
                        <th
                            v-for="(header, index) in headers"
                            :key="index"
                            @click="
                                header.sortable &&
                                    $emit(
                                        'onSort',
                                        header.value,
                                        sortOrder === 'asc' ? 'desc' : 'asc'
                                    )
                            "
                            :class="{
                                'cursor-pointer': header.sortable,
                                'active-header':
                                    header.sortable &&
                                    sortColumn === header.value,
                            }"
                        >
                            {{ header.text }}
                            <span
                                v-if="
                                    header.sortable &&
                                    sortColumn === header.value
                                "
                            >
                                <i
                                    :class="[
                                        'fa',
                                        sortOrder === 'asc'
                                            ? 'fa-sort-asc'
                                            : 'fa-sort-desc',
                                    ]"
                                ></i>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, rowIndex) in data" :key="rowIndex">
                        <td v-if="selectable">
                            <input
                                type="checkbox"
                                v-model="internalSelectedItems"
                                :value="row.id"
                            />
                        </td>
                        <td
                            v-for="(header, headerIndex) in headers"
                            :key="headerIndex"
                        >
                            {{
                                Array.isArray(row[header.value])
                                    ? row[header.value].join(', ')
                                    : formatValue(row[header.value])
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <nav class="pagination-nav">
            <p>{{ messageTableSummary }}</p>
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button
                        class="page-link"
                        @click="changePage(currentPage - 1)"
                    >
                        {{ messagePrevious }}
                    </button>
                </li>
                <li v-if="pages[0] !== 1" class="page-item">
                    <button class="page-link" @click="changePage(1)">1</button>
                </li>
                <li v-if="pages[0] > 2" class="page-item">
                    <button class="page-link" disabled>...</button>
                </li>
                <li
                    v-for="page in pages"
                    :key="page"
                    class="page-item"
                    :class="{ active: currentPage === page }"
                >
                    <button class="page-link" @click="changePage(page)">
                        {{ page }}
                    </button>
                </li>
                <li
                    v-if="pages[pages.length - 1] < totalPages - 1"
                    class="page-item"
                >
                    <button class="page-link" disabled>...</button>
                </li>
                <li
                    v-if="pages[pages.length - 1] !== totalPages"
                    class="page-item"
                >
                    <button class="page-link" @click="changePage(totalPages)">
                        {{ totalPages }}
                    </button>
                </li>
                <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                >
                    <button
                        class="page-link"
                        @click="changePage(currentPage + 1)"
                    >
                        {{ messageNext }}
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import FilterComponent from '@/components/FilterComponent.vue';
import { useI18n } from 'vue-i18n';

export default {
    components: {
        FilterComponent,
    },
    props: {
        headers: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        currentPageSize: {
            type: Number,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        sortColumn: {
            type: String,
            default: '',
        },
        sortOrder: {
            type: String,
            default: 'asc',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        filterOptions: {
            type: Array,
            default: () => [],
        },
        totalCounts: {
            type: Number,
            default: 0,
        },
        pageSizes: {
            type: Array,
            default: () => [10, 20, 50, 100],
        },
        messageSearch: {
            type: String,
        },
        messageExport: {
            type: String,
        },
        messagePageSize: {
            type: String,
        },
        messageRefresh: {
            type: String,
        },
        messagePrevious: {
            type: String,
        },
        messageNext: {
            type: String,
        },
        messageSummary: {
            type: String,
        },
        showExportButton: {
            type: Boolean,
            default: true,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        selectionMode: {
            type: String,
            default: 'multiple',
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
        searchFields: {
            type: Array,
            default: () => [],
        },
        isSearchTimeRestricted: {
            type: Boolean,
            default: false,
        },
        messageSearchTimeDays: {
            type: String,
        },
        messageSearchTimeCustom: {
            type: String,
        },
        messageSearchTimeLimit: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const filter = ref('');
        const internalSelectedItems = ref([...props.modelValue]);

        const messageTableSummary = computed(() => {
            return t(props.messageSummary, {
                start: (props.currentPage - 1) * props.currentPageSize + 1,
                end: Math.min(
                    props.currentPage * props.currentPageSize,
                    props.totalCounts
                ),
                count: props.totalCounts,
            });
        });

        const pages = computed(() => {
            const range = 2;
            let start = props.currentPage - range;
            let end = props.currentPage + range;

            if (start < 1) {
                start = 1;
                end = Math.min(start + range * 2, props.totalPages);
            }

            if (end > props.totalPages) {
                end = props.totalPages;
                start = Math.max(end - range * 2, 1);
            }

            const pages = [];
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }

            return pages;
        });

        const changePage = page => {
            if (
                page >= 1 &&
                page <= props.totalPages &&
                page !== props.currentPage
            ) {
                emit('onChangePage', page);
            }
        };

        const formatValue = value => {
            if (typeof value === 'number') {
                return Math.round(value * 100) / 100;
            }
            return value;
        };

        const toggleSelectAll = event => {
            if (props.selectionMode === 'single') {
                return;
            }
            if (event.target.checked) {
                internalSelectedItems.value = props.data.map(item => item.id);
            } else {
                internalSelectedItems.value = [];
            }
            emit('update:modelValue', internalSelectedItems.value);
        };

        watch(
            () => props.modelValue,
            newVal => {
                if (newVal !== internalSelectedItems.value) {
                    internalSelectedItems.value = [...newVal];
                }
            }
        );

        watch(internalSelectedItems, newVal => {
            if (props.selectionMode === 'single' && newVal.length > 1) {
                internalSelectedItems.value = [newVal[newVal.length - 1]];
            }
            if (newVal !== props.modelValue) {
                emit('update:modelValue', newVal);
            }
        });

        const allSelected = computed(
            () => internalSelectedItems.value.length === props.data.length
        );

        const handleCheckboxChange = event => {
            const value = event.target.value;
            if (props.selectionMode === 'single') {
                internalSelectedItems.value = [value];
            }
            emit('update:modelValue', internalSelectedItems.value);
        };

        return {
            internalSelectedItems,
            toggleSelectAll,
            handleCheckboxChange,
            allSelected,
            filter,
            messageTableSummary,
            pages,
            changePage,
            formatValue,
        };
    },
};
</script>

<style scoped>
.active-header {
    color: #7a9e9f;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-pointer:hover {
    color: #74b18f;
}
</style>
