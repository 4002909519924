<template>
    <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="card p-4" style="width: 100%; max-width: 900px">
            <div v-if="loading">Loading...</div>
            <div v-else>
                <div class="card-header text-center mb-4">
                    <h2 v-if="success">
                        {{
                            $t(
                                'users.users_title__user_verification_successful'
                            )
                        }}
                    </h2>
                    <h2 v-else>
                        {{ $t('users.users_title__user_verification_failed') }}
                    </h2>
                </div>
                <div class="card-body">
                    <p>{{ message }}</p>
                    <button
                        type="button"
                        class="btn btn-primary w-50"
                        v-if="redirecting"
                        @click="handleOkClick"
                    >
                        {{ $t('users.users_button__change_password') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import i18n from '@/api/i18n';
import { useRouter } from 'vue-router';
import { verifyResetPasswordToken } from '@/api/users';
import apiClient from '@/api/index';

export default {
    props: {
        token: {
            Type: String,
            required: true,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const router = useRouter();
        const store = useStore();

        const loading = ref(true);
        const success = ref(false);
        const message = ref('');
        const redirecting = ref(false); // to control the button shows or not

        onMounted(async () => {
            const locale = localStorage.getItem('locale') || 'en';
            await store.dispatch('i18n/loadLocale', locale);

            const responseLang = await apiClient.get(
                `/languages/lang/${locale}?disable_cache=false`
            );
            i18n.global.setLocaleMessage(locale, responseLang.data);
            i18n.global.locale.value = locale;
            try {
                const response = await verifyResetPasswordToken(props.token);
                if (response.data.valid) {
                    success.value = true;
                    message.value = t(
                        'users.users_msg__reset_password_token_verified_successful'
                    );
                } else {
                    success.value = false;
                    message.value = t(
                        'users.users_msg__user_verification_failed'
                    );
                }
            } catch (error) {
                success.value = false;
                message.value = t('users.users_msg__user_verification_failed');
            } finally {
                redirecting.value = true;
                loading.value = false;
            }
        });

        const handleOkClick = () => {
            if (success.value) {
                router.push({
                    path: `/reset-password/${props.token}`,
                });
            } else {
                router.push({
                    path: '/',
                });
            }
        };

        return {
            loading,
            success,
            message,
            redirecting,
            handleOkClick,
        };
    },
};
</script>

<style scoped>
button {
    margin-top: 20px;
}
</style>
