// useLocale.js
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { setLoading } from '@/plugins/loading';
import apiClient from '@/api/index';

export const useLocale = () => {
    const { locale } = useI18n();
    const store = useStore();
    const currentLanguage = ref(locale.value);
    const languages = ref([]);

    const fetchLanguages = async () => {
        try {
            const response = await apiClient.get('/languages/all-langs/', { skipAuthorization: true });
            languages.value = response.data.languages.map(lang => ({
                code: lang.code,
                name: lang.name,
            }));
        } catch (error) {
            console.error('Failed to load languages:', error);
        }
    };

    const changeLanguage = async language => {
        setLoading(true);
        await store.dispatch('i18n/loadLocale', language);
        localStorage.setItem('locale', language);
        window.location.reload(); // 重新整理頁面
    };

    return {
        currentLanguage,
        languages,
        changeLanguage,
        fetchLanguages,
    };
};
