import SidebarPlugin from '@/components/SidebarPlugin';
import { Card, DropDown, Button } from '@/components/index';
import VueClickAway from 'vue3-click-away';
//css assets
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/sass/paper-dashboard.scss';
import '@/assets/css/themify-icons.css';

// Bootstrap JavaScript
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
    install(app) {
        app.use(SidebarPlugin);
        app.use(VueClickAway);
        app.component('p-card', Card);
        app.component('drop-down', DropDown);
        app.component('p-button', Button);
    },
};
