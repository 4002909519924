const state = {
    showSidebar: JSON.parse(localStorage.getItem('showSidebar')) || false,
    sidebarLinks: [],
    displaySidebar(value) {
        this.showSidebar = value;
        localStorage.setItem('showSidebar', JSON.stringify(value));
    },
};

const mutations = {
    TOGGLE_SIDEBAR(state) {
        state.showSidebar = !state.showSidebar;
        localStorage.setItem('showSidebar', JSON.stringify(state.showSidebar));
    },
    SET_SIDEBAR(state, value) {
        state.showSidebar = value;
        localStorage.setItem('showSidebar', JSON.stringify(state.showSidebar));
    },
    SET_SIDEBAR_LINKS(state, links) {
        state.sidebarLinks = links;
    },
};

const actions = {
    toggleSidebar({ commit }) {
        commit('TOGGLE_SIDEBAR');
    },
    setSidebar({ commit }, value) {
        commit('SET_SIDEBAR', value);
    },
    setSidebarLinks({ commit }, links) {
        commit('SET_SIDEBAR_LINKS', links);
    },
};

const getters = {
    showSidebar: state => state.showSidebar,
    sidebarLinks: state => state.sidebarLinks,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
