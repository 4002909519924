// src/store/modules/auth.js
const state = {
    token: localStorage.getItem('token') || '',
};

const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    clearToken(state) {
        state.token = '';
    },
};

const actions = {
    signin({ commit }, token) {
        commit('setToken', token);
        localStorage.setItem('token', token);
    },
    signout({ commit }) {
        commit('clearToken');
        localStorage.removeItem('token');
    },
};

const getters = {
    isAuthenticated: state => !!state.token,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
