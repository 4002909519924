import apiClient from '.';

export const fetchDashboardDataFromAPI = async () => {
    try {
        const response = await apiClient.get('/analytics/dashboard');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
