<template>
    <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="card p-4" style="width: 100%; max-width: 400px">
            <form
                class="form-horizontal"
                @submit.prevent="handleForgotPassword"
            >
                <div class="card-header text-center mb-4">
                    <img
                        :src="systemLogo"
                        alt="Logo"
                        class="img-fluid"
                        style="max-width: 150px"
                    />
                    <h4 class="mt-3">{{ systemTitle }}</h4>
                </div>
                <div class="card-body">
                    <div class="form-group mb-3">
                        <label for="email" class="form-label">{{
                            $t('login.login_msg__forgot_password')
                        }}</label>
                        <input
                            type="email"
                            v-model="email"
                            class="form-control"
                            id="email"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <button type="submit" class="btn btn-primary w-100">
                        {{ $t('login.login_msg__forgot_password_submit') }}
                    </button>
                </div>
            </form>
            <div
                v-if="successMessage"
                class="alert alert-success mt-3"
                role="alert"
            >
                {{ successMessage }}
            </div>
            <div
                v-if="errorMessage"
                class="alert alert-danger mt-3"
                role="alert"
            >
                {{ errorMessage }}
            </div>
            <div class="mt-3 text-center">
                <button class="btn btn-link p-0" @click="goToLogin">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    {{ $t('login.login_msg__sign_in') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import i18n from '@/api/i18n';
import { sendResetPasswordLink } from '@/api/users';
import apiClient from '@/api/index';

export default {
    setup() {
        const { t } = useI18n();
        const Swal = inject('$swal');
        const router = useRouter();
        const store = useStore();

        const email = ref('');
        const successMessage = ref('');
        const errorMessage = ref('');

        const handleForgotPassword = async () => {
            try {
                await sendResetPasswordLink(email.value);
            } catch (error) {
                console.error('handle forgot password: ', error);
            }
            await Swal.fire({
                title: t('users.users_title__forgot_password_email_sent'),
                text: t('users.users_msg__forgot_password_email_sent'),
                icon: 'success',
                showConfirmButton: true,
            });
            goToLogin();
        };

        const goToLogin = () => {
            router.push('/');
        };

        onMounted(async () => {
            const locale = localStorage.getItem('locale') || 'en';
            await store.dispatch('i18n/loadLocale', locale);

            const responseLang = await apiClient.get(
                `/languages/lang/${locale}?disable_cache=false`
            );
            i18n.global.setLocaleMessage(locale, responseLang.data);
            i18n.global.locale.value = locale;
        });

        const systemLogo = computed(() => {
            const logoBase64 = store.getters['system/logo'];
            if (logoBase64) {
                return `data:image/png;base64,${logoBase64}`;
            }
            return '@/assets/img/dhc-logo.png';
        });

        const systemTitle = computed(() => {
            return store.getters['system/title'] || 'Club Assist SaaS';
        });

        return {
            email,
            handleForgotPassword,
            successMessage,
            errorMessage,
            goToLogin,
            systemTitle,
            systemLogo,
        };
    },
};
</script>
