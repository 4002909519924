<template>
    <div v-if="isVisible" class="modal-backdrop">
        <div class="modal" @click.stop>
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="close" @click="close">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
    props: {
        title: {
            type: String,
            default: 'Modal Title',
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const isVisible = ref(props.visible);

        const close = () => {
            isVisible.value = false;
            emit('close');
        };

        const confirm = () => {
            emit('confirm');
            close();
        };

        watch(
            () => props.visible,
            val => {
                isVisible.value = val;
            }
        );

        const handleKeydown = e => {
            if (e.key === 'Escape' && isVisible.value) {
                close();
            }
        };

        onMounted(() => {
            isVisible.value = props.visible;
            window.addEventListener('keydown', handleKeydown);
        });

        return {
            isVisible,
            close,
            confirm,
        };
    },
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    padding: 30px 0px;
}

.modal {
    display: flex;
    position: relative;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    flex-direction: column;
    justify-content: flex-end;
}

.modal-header,
.modal-footer {
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-title {
    margin: 0;
}

.modal-body {
    padding: 1rem;
}

.close {
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
}
</style>
