<template>
    <div class="card p-4 privacy-policy">
        <div class="d-flex justify-content-between align-items-left mb-3 w-100">
            <button
                name="backToLogin"
                class="btn btn-link p-0"
                @click="goToLogin"
            >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                {{ $t('login.login_msg__sign_in') }}
            </button>
            <LanguageSelector
                :languages="languages"
                :currentLanguage="currentLanguage"
                @language-changed="changeLanguage"
            />
        </div>

        <span v-if="isLoading" class="loading"></span>
        <div v-if="error" class="error-message">
            <h1>Error</h1>
            <p>{{ error }}</p>
        </div>
        <div v-else v-html="content"></div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { fetchPrivacyPolicy } from '@/api/privacyPolicy';
import LanguageSelector from '@/components/Navbar/LanguageSelector.vue';
import { useLocale } from '@/composables/useLocale';

export default {
    name: 'PrivacyPolicy',
    components: {
        LanguageSelector,
    },
    setup() {
        const router = useRouter();
        const { currentLanguage, languages, changeLanguage, fetchLanguages } =
            useLocale();

        const isLoading = ref(true);
        const content = ref('');
        const error = ref('');

        const loadPrivacyPolicy = async () => {
            try {
                content.value = await fetchPrivacyPolicy();
            } catch (err) {
                error.value =
                    err.response?.data?.detail ||
                    'An error occurred while loading the privacy policy.';
                console.error('Error loading privacy policy: ', err);
            } finally {
                isLoading.value = false;
            }
        };

        const goToLogin = () => {
            router.push('/');
        };

        onMounted(() => {
            fetchLanguages();
            loadPrivacyPolicy();
        });

        return {
            isLoading,
            content,
            error,
            goToLogin,
            currentLanguage,
            languages,
            changeLanguage,
        };
    },
};
</script>

<style scoped>
.privacy-policy {
    padding: 20px;
    margin: 20px;
    text-align: left;
    display: flex;
    justify-content: center; /* 水平置中 */
    align-self: center;
    align-items: flex-start; /* 垂直置中 */
    min-height: 200px; /* 設定容器最小高度，確保置中效果 */
    max-width: 1200px;
}
.loading {
    width: 48px;
    height: 48px;
    border: 5px solid black;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
</style>
