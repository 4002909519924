import axios from 'axios';
import store from '@/store/index';
import Swal from 'sweetalert2';
import i18n from '@/api/i18n';
const t = i18n.global.t;
function getBaseURL() {
    const envBaseURL = process.env.VUE_APP_API_BASE_URL;
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment) {
        console.log('Development mode');
        return '/api/v1'; // use proxy in vue.config.js
    }

    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    if (subdomain && subdomain !== 'localhost' && subdomain !== '127.0.0.1') {
        return envBaseURL.replace('api', `${subdomain}.api`);
    }

    return envBaseURL;
}

const baseURL = getBaseURL();

const apiClient = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use(
    config => {
        const token = store.state.auth.token;
        if (token) {
            if (token && !config.skipAuthorization) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    error => {
        console.error('Request was not sent');
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const { config, response } = error;
        if (config.skipAuthHandling) {
            return Promise.reject(error);
        }
        if (response) {
            if (response && response.status === 401) {
                store.dispatch('auth/signout');
                Swal.fire({
                    title: t('django.system_msg__status_code_401'),
                    text: response.data.error,
                    icon: 'error',
                    confirmButtonText: 'OK',
                }).then(result => {
                    if (result.isConfirmed) {
                        window.location.href = '/';
                    }
                });
            }
            if (response && response.status === 403) {
                Swal.fire({
                    title: t('django.system_msg__status_code_403'),
                    text: 'You do not have permission to access this page',
                    icon: 'error',
                    confirmButtonText: 'OK',
                }).then(result => {
                    if (result.isConfirmed) {
                        window.location.href = '/';
                    }
                });
            }
            if (response && response.status === 500) {
                Swal.fire({
                    title: 'Internal server error',
                    text: 'Please try again later',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
        } else {
            // 這裡你可以選擇如何處理沒有 response 的情況，比如網絡錯誤等
            console.error('Network or server error', error);
            Swal.fire({
                title: 'Network error',
                text: 'Unable to reach the server, please try again later.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }

        return Promise.reject(error);
    }
);
export default apiClient;
