<template>
    <div class="home">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <ChartCard
                    :title="$t('dashboard.dashboard_msg__test_type_counter')"
                    subTitle="Test Type"
                    :chartData="batteryTestCounterChart.data"
                    :chartOptions="batteryTestCounterChart.options"
                    :chartColors="chartColors"
                    chartType="bar"
                    chartHeight="300px"
                >
                    <template v-slot:footer>
                        <div class="stats">
                            <i class="fa fa-history"></i>
                            Updated
                            {{ batteryTestCounterChart.data.updatedAgo }}
                        </div>
                    </template>
                </ChartCard>
            </div>
            <div class="col-md-6 col-sm-12">
                <ChartCard
                    :title="
                        $t('dashboard.dashboard_msg__judgement_result_counter')
                    "
                    subTitle="Result Type"
                    :chartData="judgementResultChart.data"
                    :chartOptions="judgementResultChart.options"
                    :chartColors="chartColors"
                    chartType="pie"
                    chartHeight="100px"
                >
                    <template v-slot:footer>
                        <div class="stats">
                            <i class="fa fa-history"></i> Updated
                            {{ judgementResultChart.data.updatedAgo }}
                        </div>
                    </template>
                </ChartCard>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { ChartCard } from '@/components';
import { fetchDashboardDataFromAPI } from '@/api/dashboard';

export default {
    name: 'HomeView',
    components: {
        ChartCard,
    },
    setup() {
        const chartColors = ref([
            'rgba(255, 99, 132, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(85, 159, 135, 0.8)',
            'rgba(255, 42, 192, 0.8)',
        ]);
        const statsCards = ref([
            {
                type: 'warning',
                icon: 'ti-user',
                title: 'Active Users',
                value: '1,234',
                footerText: 'Updated now',
                footerIcon: 'ti-reload',
            },
            {
                type: 'success',
                icon: 'ti-car',
                title: 'Total Battery Tests',
                value: '5,678',
                footerText: 'Updated now',
                footerIcon: 'ti-reload',
            },
        ]);

        const batteryTestCounterChart = ref({
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'none',
                    },
                    datalabels: {
                        formatter: (value, ctx) => {
                            let sum = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage =
                                ((value * 100) / sum).toFixed(2) + '%';
                            return value + ' (' + percentage + ')';
                        },
                        color: '#fff',
                    },
                },
            },
        });
        const judgementResultChart = ref({
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    datalabels: {
                        formatter: (value, ctx) => {
                            let sum = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage =
                                ((value * 100) / sum).toFixed(2) + '%';
                            return value + ' (' + percentage + ')';
                        },
                        color: '#fff',
                    },
                },
            },
        });

        const retrieveBarChartData = result => {
            const labels = Object.keys(result.json_structure);
            const datasets = [
                {
                    label: 'Test Type',
                    data: Object.values(result.json_structure).map(
                        item => item.times
                    ),
                    backgroundColor: chartColors.value.slice(
                        0,
                        Object.values(result.json_structure).length
                    ),
                    borderColor: chartColors.value
                        .slice(0, Object.values(result.json_structure).length)
                        .map(color => color.replace('0.8', '1')),
                    borderWidth: 1,
                },
            ];
            if (result.edate) {
                const now = new Date();
                const eDate = new Date(result.edate);
                const timeDiff = now - eDate;
                const oneDay = 24 * 60 * 60 * 1000;

                if (timeDiff < oneDay) {
                    const hoursDiff = Math.floor(timeDiff / (60 * 60 * 1000));
                    return {
                        labels,
                        datasets,
                        updatedAgo: `${hoursDiff} hours ago`,
                    };
                } else {
                    const daysDiff = Math.floor(timeDiff / oneDay);
                    const hoursDiff = Math.floor(
                        (timeDiff % oneDay) / (60 * 60 * 1000)
                    );
                    return {
                        labels,
                        datasets,
                        updatedAgo: `${daysDiff} day${
                            daysDiff > 1 ? 's' : ''
                        } and ${hoursDiff} hours ago`,
                    };
                }
            } else {
                return {
                    labels,
                    datasets,
                    updatedAgo: 'No data available',
                };
            }
        };

        const retrievePieChartData = result => {
            const labels = Object.keys(result.json_structure);
            const datasets = [
                {
                    data: Object.values(result.json_structure).map(
                        item => item.times
                    ),
                    backgroundColor: chartColors.value.slice(
                        0,
                        Object.values(result.json_structure).length
                    ),
                    borderColor: chartColors.value
                        .slice(0, Object.values(result.json_structure).length)
                        .map(color => color.replace('0.2', '1')),
                    borderWidth: 1,
                },
            ];
            if (result.edate) {
                const now = new Date();
                const eDate = new Date(result.edate);
                const timeDiff = now - eDate;
                const oneDay = 24 * 60 * 60 * 1000;

                if (timeDiff < oneDay) {
                    const hoursDiff = Math.floor(timeDiff / (60 * 60 * 1000));
                    return {
                        labels,
                        datasets,
                        updatedAgo: `${hoursDiff} hours ago`,
                    };
                } else {
                    const daysDiff = Math.floor(timeDiff / oneDay);
                    const hoursDiff = Math.floor(
                        (timeDiff % oneDay) / (60 * 60 * 1000)
                    );
                    return {
                        labels,
                        datasets,
                        updatedAgo: `${daysDiff} day${
                            daysDiff > 1 ? 's' : ''
                        } and ${hoursDiff} hours ago`,
                    };
                }
            } else {
                return {
                    labels,
                    datasets,
                    updatedAgo: 'No data available',
                };
            }
        };

        onMounted(async () => {
            try {
                const response = await fetchDashboardDataFromAPI();
                const batteryTestData = response.results.find(
                    item => item.title === 'test_type_bar_chart'
                );
                const judgementResultData = response.results.find(
                    item => item.title === 'result_pie_chart'
                );

                if (batteryTestData) {
                    batteryTestCounterChart.value.data =
                        retrieveBarChartData(batteryTestData);
                }
                if (judgementResultData) {
                    judgementResultChart.value.data =
                        retrievePieChartData(judgementResultData);
                }
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        });

        return {
            statsCards,
            batteryTestCounterChart,
            judgementResultChart,
            chartColors,
        };
    },
};
</script>

<style scoped>
.home {
    text-align: center;
    margin-top: 50px;
}
</style>
