import SidebarComponent from './Sidebar.vue';
import SidebarLinkComponent from './SidebarLink.vue';

const SidebarPlugin = {
    install(app) {
        app.component('side-bar', SidebarComponent);
        app.component('sidebar-link', SidebarLinkComponent);
    },
};

export default SidebarPlugin;
