<template>
    <div class="filter-component col-lg-6 col-sm-12">
        <div class="input-group mb-3">
            <input
                type="text"
                class="form-control search-input"
                :placeholder="messageSearch"
                v-model="textFilter"
                @keyup.enter="emitTextFilter"
            />
            <select
                v-if="searchOptions && searchOptions.length > 0"
                class="form-control"
                v-model="selectedOption"
                title="Text Search Field"
            >
                <option value="" selected="selected">
                    {{ $t('users.users_msg__search_all_fields') }}
                </option>
                <option
                    v-for="item in searchOptions"
                    :value="item.key"
                    :key="item.key"
                >
                    {{ item.label }}
                </option>
            </select>
            <!--
            <button type="button" class="btn btn-primary" @click="addFilter">
                +
            </button>
            -->
        </div>
        <div v-if="isSearchTimeRestricted" class="filter-group mb-2 flex-wrap">
            <div
                class="form-group d-flex flex-row align-items-center justify-content-end"
            >
                <div class="form-check form-check-inline">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="dateRange"
                        value="7d"
                        id="7d"
                        v-model="searchTimeRangeSelection"
                        @click="setSearchTimeRange(7)"
                    />
                    <label class="form-check-label" for="7d">{{
                        $t(messageSearchTimeDays, { number: 7 })
                    }}</label>
                </div>
                <div class="form-check form-check-inline">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="dateRange"
                        value="custom"
                        id="custom"
                        v-model="searchTimeRangeSelection"
                        @click="setSearchTimeRange(7, true)"
                    />
                    <label class="form-check-label" for="custom">{{
                        $t(messageSearchTimeCustom)
                    }}</label>
                </div>
            </div>

            <div
                v-if="isCustomDateRange"
                class="form-group d-flex flex-row align-items-center justify-content-end"
            >
                <label>{{
                    $t('test_report.test_report_fld__upload_time')
                }}</label>
                <div class="text-danger text-nowrap form-control">
                    <label>
                        {{
                            $t(messageSearchTimeLimit.key, {
                                number: messageSearchTimeLimit.value,
                            })
                        }}
                    </label>
                </div>

                <input
                    title="Start Date"
                    type="date"
                    class="form-control"
                    v-model="timeRangeForSearch.start"
                    @input="updateSearchRange()"
                    :disabled="!isCustomDateRange"
                />
                <i class="fa fa-arrow-right"></i>
                <input
                    title="End Date"
                    type="date"
                    class="form-control"
                    v-model="timeRangeForSearch.end"
                    @input="updateSearchRange()"
                    :disabled="!isCustomDateRange"
                />
                <button
                    type="button"
                    class="btn btn-round btn-block btn-outline-default form-control"
                    @click="setSearchTimeRange(14, true)"
                >
                    {{ $t(messageSearchTimeDays, { number: 14 }) }}
                </button>
                <button
                    type="button"
                    class="btn btn-round btn-block btn-outline-default form-control"
                    @click="setSearchTimeRange(30, true)"
                >
                    {{ $t(messageSearchTimeDays, { number: 30 }) }}
                </button>
            </div>
        </div>
        <div
            v-for="(filter, index) in activeFilters"
            :key="index"
            class="filter-group mb-2"
        >
            <label>{{ filter.label }}</label>
            <div v-if="filter.type === 'string'">
                <input
                    type="text"
                    class="form-control"
                    v-model="filter.value"
                    @keyup.enter="updateFilter(index)"
                />
            </div>
            <div
                class="form-group d-flex flex-row align-items-center"
                v-else-if="filter.type === 'datetime'"
            >
                <button
                    type="button"
                    class="btn btn-round btn-block btn-outline-default form-control"
                    @click="setDateRange(index, 7)"
                >
                    7 Days
                </button>
                <button
                    type="button"
                    class="btn btn-round btn-block btn-outline-default form-control"
                    @click="setDateRange(index, 14)"
                >
                    14 Days
                </button>
                <button
                    type="button"
                    class="btn btn-round btn-block btn-outline-default form-control"
                    @click="setDateRange(index, 30)"
                >
                    30 Days
                </button>
                <input
                    title="Start Date"
                    type="date"
                    class="form-control"
                    v-model="filter.start"
                    @input="updateDateTimeFilter(index)"
                />
                <i class="fa fa-arrow-right"></i>
                <input
                    title="End Date"
                    type="date"
                    class="form-control"
                    v-model="filter.end"
                    @input="updateDateTimeFilter(index)"
                />
            </div>
            <div v-else-if="filter.type === 'select'">
                <select
                    class="form-control"
                    v-model="filter.value"
                    @change="updateFilter(index)"
                >
                    <option
                        v-for="option in filter.options"
                        :value="option[0]"
                        :key="option[0]"
                    >
                        {{ option[1] }}
                    </option>
                </select>
            </div>
            <button
                type="button"
                class="btn btn-danger"
                @click="removeFilter(index)"
            >
                -
            </button>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, watch, watchEffect } from 'vue';

export default {
    props: {
        filterOptions: {
            type: Array,
            default: () => [],
        },
        messageSearch: {
            type: String,
            default: 'Search...',
        },
        searchFields: {
            type: Array,
            default: () => [],
        },
        isSearchTimeRestricted: {
            type: Boolean,
            default: false,
        },
        searchTimeRange: {
            type: Object,
            default: () => ({
                type: 'datetime',
                key: 'upload_time',
                start: '',
                end: '',
            }),
        },
        messageSearchTimeDays: {
            type: String,
            default: 'test_report.test_report_msg__search_time_range_days',
        },
        messageSearchTimeCustom: {
            type: String,
            default: 'test_report.test_report_msg__search_time_range_custom',
        },
        messageSearchTimeLimit: {
            type: Object,
            default: () => ({
                key: 'test_report.test_report_msg__search_time_range_limit_days',
                value: 180,
            }),
        },
    },
    setup(props, { emit }) {
        const textFilter = ref('');
        const selectedOption = ref('');
        const activeFilters = ref([]);
        const availableFilters = ref([...props.filterOptions]);
        const searchOptions = ref([...props.searchFields]);
        const timeRangeForSearch = ref({ ...props.searchTimeRange });
        const isCustomDateRange = ref(false);
        const searchTimeRangeSelection = ref('7d');

        const emitTextFilter = () => {
            if (props.isSearchTimeRestricted) {
                if (
                    timeRangeForSearch.value.start === '' ||
                    timeRangeForSearch.value.end === ''
                ) {
                    setSearchTimeRange(7);
                    searchTimeRangeSelection.value = '7d';
                }
            }

            emit('onTextFilter', {
                text: textFilter.value,
                field: selectedOption.value,
            });
        };

        const addFilter = () => {
            if (availableFilters.value.length > 0) {
                const newFilter = availableFilters.value.shift();
                activeFilters.value.push({
                    ...newFilter,
                    value: '',
                    start: '',
                    end: '',
                });
            }
        };

        const removeFilter = index => {
            const removedFilter = activeFilters.value.splice(index, 1)[0];
            availableFilters.value.push(removedFilter);
        };

        const updateDateTimeFilter = index => {
            const filter = activeFilters.value[index];
            if (filter.start && filter.end) {
                emit('onFilter', {
                    type: filter.type,
                    key: filter.key,
                    value: filter.value,
                    start: filter.start,
                    end: filter.end,
                });
            }
        };

        const updateSearchRange = () => {
            emit('onFilter', {
                type: timeRangeForSearch.value.type,
                key: timeRangeForSearch.value.key,
                start: timeRangeForSearch.value.start,
                end: timeRangeForSearch.value.end,
            });
        };

        const updateFilter = index => {
            const filter = activeFilters.value[index];
            emit('onFilter', {
                type: filter.type,
                key: filter.key,
                value: filter.value,
                start: filter.start,
                end: filter.end,
            });
        };

        watch(
            () => props.filterOptions,
            newOptions => {
                availableFilters.value = [...newOptions];
                checkAndAddUploadTimeFilter();
            }
        );

        watch(
            () => props.searchFields,
            newSearchFields => {
                searchOptions.value = [...newSearchFields];
            }
        );

        const setDateRange = (index, days) => {
            const filter = activeFilters.value[index];
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - days);
            activeFilters.value[index] = {
                ...filter,
                start: startDate.toISOString().slice(0, 10),
                end: endDate.toISOString().slice(0, 10),
            };
            updateDateTimeFilter(index);
        };

        const setSearchTimeRange = (days, isCustom = false) => {
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(startDate.getDate() - days);
            timeRangeForSearch.value.start = startDate
                .toISOString()
                .slice(0, 10);
            timeRangeForSearch.value.end = endDate.toISOString().slice(0, 10);
            emit('onFilter', {
                type: timeRangeForSearch.value.type,
                key: timeRangeForSearch.value.key,
                start: timeRangeForSearch.value.start,
                end: timeRangeForSearch.value.end,
            });
            if (isCustom) {
                isCustomDateRange.value = true;
            } else {
                isCustomDateRange.value = false;
            }
        };

        const checkAndAddUploadTimeFilter = () => {
            const uploadTimeFilter = findUploadTimeFilter();
            if (uploadTimeFilter) {
                addUploadTimeFilter(uploadTimeFilter);
            }
        };

        const findUploadTimeFilter = () => {
            return availableFilters.value.find(
                filter => filter.key === 'upload_time'
            );
        };

        const addUploadTimeFilter = uploadTimeFilter => {
            activeFilters.value.push({
                ...uploadTimeFilter,
                value: '',
                start: '',
                end: '',
            });
            availableFilters.value = availableFilters.value.filter(
                filter => filter.key !== 'upload_time'
            );
        };

        watchEffect(() => {
            timeRangeForSearch.value = { ...props.searchTimeRange };
        });

        onMounted(() => {
            // 預設開啟 upload_time filter
            // checkAndAddUploadTimeFilter();
        });

        return {
            textFilter,
            selectedOption,
            searchOptions,
            activeFilters,
            availableFilters,
            emitTextFilter,
            addFilter,
            removeFilter,
            updateDateTimeFilter,
            updateFilter,
            setDateRange,
            setSearchTimeRange,
            updateSearchRange,
            isCustomDateRange,
            timeRangeForSearch,
            searchTimeRangeSelection,
        };
    },
};
</script>

<style scoped>
.filter-group {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}

.filter-group label {
    flex: 0 0 100px;
}

.form-control {
    margin: 15px 3px;
}

.filter-group .form-control {
    flex: 1;
}

.form-check-label {
    white-space: nowrap;
}

button.form-control {
    white-space: nowrap;
}

button.form-control:focus {
    outline: none;
    background-color: #495057;
}

/* 設定初始的 input 寬度，和放大效果 */
.input-group .search-input {
    width: 150px; /* 初始寬度 */
    transition: all 0.3s ease; /* 加入過渡效果 */
}

.input-group .search-input:focus {
    width: 300px; /* 獲得焦點時的寬度 */
}

.input-group {
    display: flex; /* 保持原有的彈性布局 */
    align-items: center; /* 對齊元素 */
}

.input-group .form-control {
    flex-shrink: 0; /* 避免縮小 */
}

.input-group .btn {
    margin-left: 5px; /* 給按鈕一些間距 */
}

.form-check-inline {
    margin-left: 0.5rem;
}
</style>
