<template>
    <li :class="['nav-item', { active: isActive }]" @click="hideSidebar">
        <router-link :to="to" class="nav-link" v-bind="$attrs">
            <slot>
                <i v-if="icon" :class="icon"></i>
                <p>{{ name }}</p>
            </slot>
        </router-link>
    </li>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'SidebarLinkComponent',
    inheritAttrs: false,
    props: {
        name: String,
        icon: String,
        to: {
            type: String,
            required: true,
        },
    },
    inject: {
        autoClose: {
            default: true,
        },
        addLink: {
            default: () => {},
        },
        removeLink: {
            default: () => {},
        },
    },
    computed: {
        isActive() {
            return this.$route.path === this.to;
        },
    },
    methods: {
        ...mapActions('sidebar', ['setSidebar']),
    },
    mounted() {
        if (this.addLink) {
            this.addLink(this);
        }
    },
    beforeUnmount() {
        if (this.removeLink) {
            this.removeLink(this);
        }
    },
};
</script>

<style scoped></style>
