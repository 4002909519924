import { register } from 'register-service-worker';

/* eslint-disable no-console */

if (process.env.NODE_ENV === 'production' && !window.location.search.includes('no-sw')) {
    console.log('You are in production mode');
    let serviceWorkRegistration;

    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            );
        },
        registered(registration) {
            console.log('Service worker has been registered.');
            serviceWorkRegistration = registration;
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content is available; refreshing.');

            if (registration && registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });

                // 當新的 Service Worker 已控制該頁面後，重新整理頁面
                registration.waiting.addEventListener('statechange', event => {
                    if (event.target.state === 'activated') {
                        window.location.reload(true); // `true` 表示強制刷新（類似於 Shift + F5）
                    }
                });
            }
        },
        offline() {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
        unsupported() {
            console.log('Service worker is not supported by the browser.');
        },
    });
}
