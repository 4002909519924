<template>
    <div>
        <TableComponent
            v-model="selectedItems"
            :headers="headers"
            :data="data"
            :current-page="currentPage"
            :currentPageSize="params.page_size"
            :total-pages="totalPages"
            :loading="loading"
            :sortColumn="sortColumn"
            :sortOrder="sortOrder"
            :filterOptions="filterFields"
            :totalCounts="totalCounts"
            :showExportButton="false"
            :selectable="true"
            :messageRefresh="$t('email_blacklist.emailblacklist_msg__refresh')"
            :messagePrevious="
                $t('email_blacklist.emailblacklist_msg__previous')
            "
            :messageNext="$t('email_blacklist.emailblacklist_msg__next')"
            :messagePageSize="
                $t('email_blacklist.emailblacklist_msg__page_size')
            "
            :messageSearch="
                $t('email_blacklist.emailblacklist_msg__search_placeholder')
            "
            :messageSummary="'email_blacklist.emailblacklist_msg__table_summary'"
            :pageSizes="[10, 20, 50, 100]"
            :searchFields="searchFields"
            @on-refresh="fetchData"
            @on-change-page="changePage"
            @on-change-page-size="changePageSize"
            @on-filter="filterData"
            @on-text-filter="textFilterData"
            @on-sort="sortData"
        >
            <template v-slot:table-actions>
                <li class="nav-item">
                    <button
                        class="nav-link"
                        href="#"
                        @click="deleteSelectedItems"
                    >
                        <i class="fa fa-trash-o"></i>
                        <p>
                            {{
                                $t('email_blacklist.emailblacklist_msg__delete')
                            }}
                        </p>
                    </button>
                </li>
            </template>
        </TableComponent>
    </div>
</template>

<script>
import {
    fetchEmailBlackListFromAPI,
    fetchConfig,
    deleteEmailBlackListFromAPI,
} from '@/api/configs/email-blacklist';
import TableComponent from '@/components/TableComponent.vue';
import { ref, onMounted, inject } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    components: {
        TableComponent,
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const columnOrder = ref([]);
        const headers = ref([]);
        const data = ref([]);
        const pageSize = ref(10);
        const currentPage = ref(1);
        const totalPages = ref(1);
        const totalCounts = ref(0);
        const filter = ref('');
        const filterFields = ref([]);
        const searchFields = ref([]);
        const sortColumn = ref('');
        const sortOrder = ref('desc');
        const loading = ref(false);
        const params = ref({});
        const selectedItems = ref([]);

        const Swal = inject('$swal');

        const buildParams = () => {
            return {
                page: currentPage.value,
                page_size: pageSize.value,
                search: filter.value,
            };
        };

        const fetchConfigData = async () => {
            const response = await fetchConfig();
            const sortableColumns = response.ordering_fields;
            columnOrder.value = response.display_fields;
            headers.value = columnOrder.value.map(key => ({
                text: t(`email_blacklist.emailblacklist_fld__${key}`) || t(key),
                value: key,
                sortable: sortableColumns.includes(key),
            }));
            const responseFilterFields = response.filter_fields;
            filterFields.value = Object.keys(responseFilterFields).map(key => ({
                key: key,
                label: t(`email_blacklist.emailblacklist_fld__${key}`) || key,
                type: responseFilterFields[key].type,
                value: '',
                start: '',
                end: '',
                options: responseFilterFields[key].items || [],
            }));
            if (response.search_fields) {
                searchFields.value = response.search_fields.map(key => ({
                    key: key,
                    label:
                        t(`email_blacklist.emailblacklist_fld__${key}`) || key,
                }));
            }
        };

        const fetchData = async () => {
            loading.value = true;
            try {
                const response = await fetchEmailBlackListFromAPI(params.value);
                data.value = response.results;
                currentPage.value = response.current_page;
                totalCounts.value = response.total_count;
                totalPages.value = response.total_pages;
            } catch (error) {
                console.error(error);
            } finally {
                loading.value = false;
            }
        };

        const changePage = page => {
            if (page >= 1 && page <= totalPages.value) {
                params.value.page = page;
                fetchData();
            }
        };

        const changePageSize = size => {
            params.value.page_size = size;
            fetchData();
        };

        const filterData = filter => {
            if (filter.type === 'datetime') {
                params.value[`${filter.key}__gte`] = filter.start;
                params.value[`${filter.key}__lte`] = filter.end;
            }
            params.value.page = 1;
            fetchData();
        };

        const sortData = (columnIndex, order) => {
            sortColumn.value = columnIndex;
            sortOrder.value = order;
            params.value.ordering = `${sortOrder.value === 'desc' ? '-' : ''}${
                sortColumn.value
            }`;
            params.value.page = 1;
            fetchData();
        };

        const textFilterData = search => {
            if (search.field !== '') {
                params.value.search_fields = search.field;
            } else {
                params.value.search_fields = searchFields.value
                    .map(item => item.key)
                    .join(',');
            }
            params.value.search = search.text;
            params.value.page = 1;
            sortColumn.value = '';
            sortOrder.value = 'desc';
            delete params.value.ordering;
            fetchData();
        };

        const deleteSelectedItems = async () => {
            if (selectedItems.value.length === 0) {
                await Swal.fire({
                    title: t(
                        'email_blacklist.emailblacklist_msg__please_select_at_least_one_email_to_delete'
                    ),
                    icon: 'warning',
                    showConfirmButton: true,
                });
                return;
            }
            try {
                const { value } = await Swal.fire({
                    title: t(
                        'email_blacklist.emailblacklist_msg__delete_email_blacklist'
                    ),
                    text: t(
                        'email_blacklist.emailblacklist_msg__are_you_sure_you_want_to_delete_selected_email_blacklist'
                    ),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t(
                        'email_blacklist.emailblacklist_button__confirm'
                    ),
                    cancelButtonText: t(
                        'email_blacklist.emailblacklist_button__cancel'
                    ),
                });
                if (!value) {
                    return;
                } else {
                    loading.value = true;
                    for (const id of selectedItems.value) {
                        await deleteEmailBlackListFromAPI(id);
                    }
                    selectedItems.value = [];
                    emit('update:modelValue', []);
                }
            } catch (error) {
                console.error(error);
            } finally {
                loading.value = false;
                await fetchData();
            }
        };

        onMounted(async () => {
            await fetchConfigData();
            params.value = buildParams();
            await fetchData();
        });

        return {
            headers,
            data,
            currentPage,
            totalPages,
            totalCounts,
            filter,
            filterFields,
            searchFields,
            sortColumn,
            sortOrder,
            loading,
            params,
            selectedItems,
            fetchData,
            changePage,
            changePageSize,
            filterData,
            sortData,
            textFilterData,
            deleteSelectedItems,
        };
    },
};
</script>
