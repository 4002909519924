import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    legacy: false,
    fallbackLocale: 'en',
    messages: {},
});

export const setI18nLocale = (locale, messages) => {
    i18n.global.locale.value = locale;
    i18n.global.setLocaleMessage(locale, messages);
};

export default i18n;